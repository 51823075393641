var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "manage-user" },
    [
      _vm.joined
        ? _c("UserIcon", {
            staticClass: "manage-user__icon",
            attrs: { userId: _vm.user.userId, userStatus: "dark" },
          })
        : _vm._e(),
      _c("div", { staticClass: "manage-user__main" }, [
        _vm.name
          ? _c("div", { staticClass: "manage-user__name" }, [
              _vm._v(_vm._s(_vm.name) + " "),
              _c("span", { staticClass: "pronouns" }, [
                _vm._v(_vm._s(_vm.pronouns)),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "manage-user__email" }, [
          _vm._v(_vm._s(_vm.user.email)),
        ]),
      ]),
      _vm.user.field1 || _vm.user.field2
        ? _c("div", { staticClass: "manage-user__fields" }, [
            _vm.user.field1
              ? _c("div", [_vm._v(_vm._s(_vm.user.field1))])
              : _vm._e(),
            _vm.user.field1
              ? _c("div", [_vm._v(_vm._s(_vm.user.field2))])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.joined
        ? _c("div", { staticClass: "manage-user__date" }, [
            _vm._v("Joined: " + _vm._s(_vm.date)),
          ])
        : _vm._e(),
      _vm.pending
        ? _c("TextInput", {
            staticClass: "manage-user__inviteURL",
            attrs: {
              readonly: "",
              inset: "",
              icon: require("@/assets/copy.svg"),
              copyOnClick: "",
              label: "Click to copy invite link",
            },
            model: {
              value: _vm.inviteURL,
              callback: function ($$v) {
                _vm.inviteURL = $$v
              },
              expression: "inviteURL",
            },
          })
        : _vm._e(),
      _vm.joined
        ? _c("div", { staticClass: "manage-user__toggleAdmin" }, [
            _c(
              "button",
              {
                staticClass: "user",
                class: { selected: !_vm.user.isAdmin && !_vm.isBusy },
                on: {
                  click: function ($event) {
                    return _vm.toggleAdmin(false)
                  },
                },
              },
              [_vm._v(" User ")]
            ),
            _c(
              "button",
              {
                staticClass: "admin",
                class: { selected: _vm.user.isAdmin && !_vm.isBusy },
                on: {
                  click: function ($event) {
                    return _vm.toggleAdmin(true)
                  },
                },
              },
              [_vm._v(" Admin ")]
            ),
          ])
        : _vm._e(),
      _vm.joined
        ? _c("div", { staticClass: "manage-user__toggle-warning" }, [
            _vm._v("Please note: For user type changes to take effect "),
            _c("br"),
            _vm._v(" the user will need to leave the Space and rejoin."),
          ])
        : _vm._e(),
      _vm.canRemove
        ? _c("btn", {
            attrs: { xlarge: "", colour: "red" },
            on: { onClick: _vm.removeUser },
            scopedSlots: _vm._u(
              [
                {
                  key: "text",
                  fn: function () {
                    return [_vm._v("Remove user from this Space")]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1587774627
            ),
          })
        : _vm._e(),
      _vm.canCancelInvite
        ? _c("btn", {
            attrs: { xlarge: "", colour: "ghost" },
            on: { onClick: _vm.cancelInvite },
            scopedSlots: _vm._u(
              [
                {
                  key: "text",
                  fn: function () {
                    return [_vm._v("Cancel Space invite")]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3931400747
            ),
          })
        : _vm._e(),
      _vm.canReInvite
        ? _c("btn", {
            attrs: { xlarge: "", colour: "green" },
            on: { onClick: _vm.reInvite },
            scopedSlots: _vm._u(
              [
                {
                  key: "text",
                  fn: function () {
                    return [_vm._v("Re-invite")]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1458627219
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }