var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container-users" },
    [
      _c("modal-header", [
        _vm._v(" Add new users "),
        _c(
          "span",
          {
            staticClass: "limit-count",
            class: `${_vm.limitExceeded > -1 ? "exceeded" : ""}`,
          },
          [
            _vm._v(
              "(" +
                _vm._s(_vm.areaUsersNo) +
                " / " +
                _vm._s(_vm.areaLimit) +
                ")"
            ),
          ]
        ),
      ]),
      _vm.validEmails
        ? _c(
            "div",
            { staticClass: "emails-list" },
            _vm._l(_vm.validEmails, function (email) {
              return _c("div", { key: email, staticClass: "item" }, [
                _c("div", { staticClass: "email-wrapper" }, [
                  _c("span", { staticClass: "email" }, [_vm._v(_vm._s(email))]),
                ]),
                _c(
                  "span",
                  {
                    staticClass: "remove",
                    attrs: { id: email },
                    on: { click: _vm.removeEmailFromList },
                  },
                  [_c("p", { staticClass: "remove-x" })]
                ),
              ])
            }),
            0
          )
        : _vm._e(),
      _vm.limitExceeded < 0
        ? _c(
            "div",
            { staticClass: "emails-list-inputs" },
            [
              _c("TextInput", {
                attrs: {
                  inset: "",
                  multiline: "",
                  focusOnMounted: "",
                  placeholder:
                    "Add user email(s) separated by commas or spaces",
                },
                on: { onInput: _vm.onEmailInput },
                model: {
                  value: _vm.emailInput,
                  callback: function ($$v) {
                    _vm.emailInput = $$v
                  },
                  expression: "emailInput",
                },
              }),
            ],
            1
          )
        : _c("div", { staticClass: "exceeded-area-limit" }, [
            _vm._v(" You have exceeded the user limit for this Space."),
            _c("br"),
            _vm._v(" "),
            _c("br"),
            _vm._m(0),
          ]),
      _c(
        "FooterButtons",
        [
          _c("btn", {
            attrs: { colour: "ghost" },
            on: { onClick: _vm.cancel },
            scopedSlots: _vm._u([
              {
                key: "text",
                fn: function () {
                  return [_vm._v("Cancel")]
                },
                proxy: true,
              },
            ]),
          }),
          _c("btn", {
            attrs: { colour: "green", disabled: !_vm.allowAddUsers },
            on: { onClick: _vm.addAllEmails },
            scopedSlots: _vm._u([
              {
                key: "text",
                fn: function () {
                  return [_vm._v("Save")]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _vm._v("Please contact "),
      _c("a", { attrs: { href: "https://spacein.co.uk", target: "_blank" } }, [
        _vm._v("SPACEIN team"),
      ]),
      _vm._v(" to upgrade your Space and add more users."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }