var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "manage-users" }, [
    _c("div", { staticClass: "manage-users-heading" }, [
      _c("h1", { staticClass: "manage-users-heading__title" }, [
        _vm._v("Manage Users"),
      ]),
      _c("div", { staticClass: "manage-users-heading-buttons" }, [
        !_vm.selectMode
          ? _c(
              "button",
              {
                staticClass: "manage-users-button manage-users-facets",
                on: {
                  click: function ($event) {
                    _vm.showFacets = !_vm.showFacets
                  },
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.selectedFacet.name) +
                    " (" +
                    _vm._s(_vm.selectedFacet.count) +
                    ") "
                ),
                _c("img", {
                  style: {
                    transform: _vm.showFacets ? "rotate(180deg)" : null,
                  },
                  attrs: {
                    src: require("@/assets/manage-users/dropdown-arrow.svg"),
                  },
                }),
                _vm.showFacets
                  ? _c(
                      "div",
                      { staticClass: "manage-users-facets-dropdown" },
                      _vm._l(_vm.facets, function (f) {
                        return _c(
                          "div",
                          {
                            key: f.name,
                            staticClass: "manage-users-facets-dropdown-item",
                            class: {
                              "manage-users-facets-dropdown-item--selected":
                                _vm.selectedFacet.name === f.name,
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.changeFilter(f)
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/manage-users/dropdown-tick.svg"),
                              },
                            }),
                            _vm._v(" " + _vm._s(f.name) + " "),
                            _c("span", [_vm._v("(" + _vm._s(f.count) + ")")]),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ]
            )
          : _vm._e(),
        _vm.selectMode
          ? _c(
              "button",
              {
                class: _vm.suffix("manage-users-button", ["-remove"]),
                on: { click: _vm.batchDelete },
              },
              [_vm._v(" Remove from this Space ")]
            )
          : _vm._e(),
        _c(
          "button",
          {
            class: _vm.suffix("manage-users-button", [
              _vm.selectMode ? "-select" : "",
            ]),
            on: { click: _vm.toggleSelectMode },
          },
          [_vm._v(" " + _vm._s(_vm.selectMode ? "Cancel" : "Select") + " ")]
        ),
      ]),
    ]),
    _c("div", { staticClass: "manage-users-content" }, [
      _c(
        "div",
        { staticClass: "manage-users__cta-group" },
        [
          _c(
            "div",
            {
              staticClass: "manage-users-invite",
              on: { click: _vm.inviteUsers },
            },
            [
              _c("img", {
                staticClass: "manage-users-invite__icon",
                attrs: { src: require("@/assets/invite_user_icon.svg") },
              }),
              _vm._v(" Invite new user(s) "),
            ]
          ),
          _c(
            "BespokeInput",
            {
              staticClass: "manage-users__search",
              attrs: {
                iconClick:
                  _vm.search || _vm.searchFocus
                    ? () => (_vm.search = "")
                    : null,
                icon: require(_vm.search || _vm.searchFocus
                  ? "@/assets/close-link-button.svg"
                  : "@/assets/search.svg"),
              },
              on: {
                focus: function ($event) {
                  _vm.searchFocus = true
                },
                blur: function ($event) {
                  _vm.searchFocus = false
                },
              },
              model: {
                value: _vm.search,
                callback: function ($$v) {
                  _vm.search = $$v
                },
                expression: "search",
              },
            },
            [
              _c("span", { staticClass: "text-italic" }, [
                _c("b", [_vm._v("Search")]),
                _vm._v(" "),
                _c("span", { staticClass: "text-mid-grey" }, [_vm._v("Users")]),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "manage-users-table-row-heading manage-users-table-row",
        },
        [
          _c(
            "div",
            { staticClass: "manage-users-table-row-content" },
            _vm._l(_vm.headings, function (name, key) {
              return _c(
                "div",
                {
                  key: key,
                  class: [
                    "manage-users-table-cell",
                    "manage-users-table-cell-" + key,
                    "manage-users-table-cell-heading",
                    {
                      "manage-users-table-cell-heading--selected":
                        _vm.sortBy === key,
                    },
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.headingClick(key)
                    },
                  },
                },
                [
                  _vm._v(" " + _vm._s(name) + " "),
                  _vm.sortBy === key
                    ? _c("img", {
                        staticClass: "manage-users-table-heading-arrow",
                        style: {
                          transform: _vm.sortDescending
                            ? null
                            : "rotate(180deg)",
                        },
                        attrs: {
                          src: require("@/assets/manage-users/descending-arrow.svg"),
                        },
                      })
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
        ]
      ),
      _c(
        "div",
        {
          ref: "table",
          staticClass: "manage-users-table",
          on: { scroll: _vm.handleScroll },
        },
        [
          _vm._l(_vm.users, function (user) {
            return _c(
              "div",
              {
                key: user.id,
                class: _vm.suffix("manage-users-table-row", [
                  _vm.selected.includes(user.userId) ? "-selected" : "",
                ]),
                on: {
                  click: function ($event) {
                    return _vm.rowClick(user)
                  },
                },
              },
              [
                _vm.isDesktop
                  ? _c(
                      "div",
                      { staticClass: "manage-users-table-row-content" },
                      _vm._l(_vm.headings, function (name, key) {
                        return _c(
                          "div",
                          {
                            key: key,
                            class: _vm.suffix("manage-users-table-cell", [
                              ..._vm.suffix("-" + key, [
                                key === "isAdmin"
                                  ? "-" + _vm.getUserType(user).toLowerCase()
                                  : "",
                              ]),
                            ]),
                          },
                          [
                            key === "icon"
                              ? _c("UserIcon", {
                                  staticClass: "manage-users-table-cell__icon",
                                  attrs: { userId: user.userId || " " },
                                })
                              : [
                                  _vm._v(
                                    _vm._s(_vm.computeCellText(user, key))
                                  ),
                                ],
                          ],
                          2
                        )
                      }),
                      0
                    )
                  : _c(
                      "div",
                      { staticClass: "manage-users-table-row-content" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "manage-users-table-cell__imageColumn",
                          },
                          [
                            _c("UserIcon", {
                              staticClass: "manage-users-table-cell__icon",
                              attrs: { userId: user.userId || "" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "manage-users-table-cell__textColumn",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "manage-users-table-cell--fullName",
                              },
                              [
                                _vm._v(
                                  _vm._s(user.firstName) +
                                    " " +
                                    _vm._s(user.lastName) +
                                    " "
                                ),
                                _c("span", { staticClass: "pronouns" }, [
                                  _vm._v(
                                    _vm._s(
                                      user.pronouns ? `(${user.pronouns})` : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "manage-users-table-cell--email" },
                              [_vm._v(_vm._s(user.email || "-"))]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "manage-users-table-cell--isAdmin",
                                class: [
                                  _vm.suffix(
                                    "manage-users-table-cell--isAdmin",
                                    ["-" + _vm.getUserType(user).toLowerCase()]
                                  ),
                                ],
                              },
                              [_vm._v(_vm._s(_vm.getUserType(user)))]
                            ),
                          ]
                        ),
                      ]
                    ),
              ]
            )
          }),
          _vm.requestNonce
            ? _vm._l(3, function (i) {
                return _c(
                  "Skeleton",
                  {
                    key: i,
                    attrs: {
                      size: "100% auto",
                      margin: "0 0 10px 0",
                      "rounded-full": "",
                      shimmer: "",
                    },
                  },
                  [
                    _c("Skeleton", {
                      attrs: { size: "34px", margin: "3px", circle: "" },
                    }),
                  ],
                  1
                )
              })
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }